import React, { Component } from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import flatten from "lodash.flatten";

import { Treemap, BarChart } from "d3plus-react";

export default class MonthlyExpenses extends Component {
  state = {
    data: null,
    chart: "treemap"
  };

  componentDidMount() {
    const { client } = this.props;

    client.getMonthlyExpenses().then(resp => {
      const values = resp.result.values;
      const months = values[0].slice(2);
      const data = flatten(
        values.slice(1, -1).map(v => {
          const d = [];
          for (var i = 0; i < months.length; i++) {
            const [y, m] = months[i].split("/");
            d.push({
              categoria: v[0],
              concepto: v[1],
              monto: v[2 + i],
              mes: new Date(parseInt(y, 10), parseInt(m, 10) - 1, 1)
            });
          }
          return d;
        })
      );

      this.setState({
        data
      });
    });
  }

  renderChart() {
    const { chart, data } = this.state;
    switch (chart) {
      case "treemap":
        return (
          <Treemap
            config={{
              height: 500,
              groupBy: ["categoria", "concepto"],
              label: d => d["concepto"],
              sum: d => d["monto"],
              total: d => d["monto"],
              time: d => d["mes"],
              data: data,
              legendConfig: {
                label: d => d["categoria"]
              },
              tooltipConfig: {
                body: d => d["monto"]
              }
            }}
          />
        );
      case "barchart":
        return (
          <BarChart
            config={{
              data,
              stacked: true,
              x: d => {
                return `${d["mes"].getYear()}-${d["mes"].getMonth() + 1}`;
              },
              y: d => d["monto"],
              id: d => d["categoria"],
              groupBy: d => d.categoria
            }}
          />
        );
      default:
        break;
    }
  }

  render() {
    if (this.state.data === null) return null;

    return (
      <div>
        <RadioGroup
          aria-label="Chart"
          name="gender1"
          value={this.state.chart}
          onChange={event => {
            this.setState({ chart: event.target.value });
          }}
          row={true}
        >
          <FormControlLabel
            value="treemap"
            control={<Radio />}
            label="Treemap"
          />
          <FormControlLabel value="barchart" control={<Radio />} label="Bars" />
        </RadioGroup>
        {this.renderChart()}
      </div>
    );
  }
}
