import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Snackbar from "@material-ui/core/Snackbar";
import TextField from "@material-ui/core/TextField";
import { createTheme } from "@material-ui/core/styles";
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from '@material-ui/pickers';

import React, { Component } from "react";

import ArrowDownIcon from "@material-ui/icons/ArrowDownward";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import MomentUtils from '@date-io/moment';
import moment from "moment";

import SelectDownshift from "./SelectDownshift.js";
import SelectorDialog from "./SelectorDialog.js";
import { entityIcon } from "./helpers.js";

const defaultTheme = createTheme();

const INITIAL_STATE = {
  selectedDate: new Date(),
  selectedDescription: "",
  selectedOrigin: null,
  selectedDestination: null,
  selectedCurrency: null,
  selectedAmount: "",

  isOriginsDialogOpen: false,
  isDestinationsDialogOpen: false,

  isLoading: true,
  snackOpen: false,
  snackContent: "",
};

class ExpenseForm extends Component {
  state = {
    optionsDestinations: [],
    optionsOrigins: [],
    optionsCurrencies: [],
    ...INITIAL_STATE,
  };

  componentDidMount() {
    const { client } = this.props;

    Promise.all([
      client.getOrigins(),
      client.getDestinations(),
      client.getCurrencies(),
    ]).then(([origins, destinations, currencies]) => {
      this.setState({
        isLoading: false,
        optionsOrigins: origins.map((origin) => ({
          label: origin.value,
          value: {
            value: origin.value,
            ...origin,
          },
        })),
        optionsDestinations: destinations.map((destination) => ({
          label: destination.value,
          value: {
            value: destination.value,
            ...destination,
          },
        })),
        optionsCurrencies: currencies.result.values.map((currency) => ({
          label: currency[0],
          value: currency[0],
        })),
      });
    });
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  validate() {
    return [
      this.state.selectedDate,
      this.state.selectedDescription,
      this.state.selectedOrigin,
      this.state.selectedDestination,
      this.state.selectedCurrency,
      this.state.selectedAmount,
    ].every((s) => s !== null);
  }

  submitExpense() {
    const { client } = this.props;
    this.setState({
      snackOpen: true,
      snackContent: "Guardando…",
      isLoading: true,
    });

    client
      .getExchangeRateToUSD(this.state.selectedCurrency)
      .then((rate) =>
        client.appendTransaction(
          moment(this.state.selectedDate).format("DD/MM/YYYY"),
          this.state.selectedDescription,
          this.state.selectedOrigin.value.value,
          this.state.selectedDestination.value.value,
          this.state.selectedCurrency,
          rate,
          this.state.selectedAmount.replace(/,/g, "").replace(/\./g, ",")
        )
      )
      .then(
        (response) => {
          this.setState({ snackContent: "Liz Taylor!", isLoading: false });
        },
        (error) => {
          this.setState({ snackContent: "Error! :(", isLoading: false });
          console.error("ERROR", error);
        }
      );
  }

  render() {
    return (
      <div style={{ margin: defaultTheme.spacing(2), position: "relative" }}>
        {this.state.isLoading && (
          <div
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              zIndex: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress size={80} />
          </div>
        )}
        <FormControl fullWidth margin="normal">
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePicker
               margin="normal"
              label="Fecha"
              format="DD/MM/yyyy"
              value={this.state.selectedDate}
              onChange={(date) => this.setState({ selectedDate: date })}
              disabled={this.state.isLoading}
            />
           </MuiPickersUtilsProvider>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <TextField
            required
            id="description"
            label="Concepto"
            value={this.state.selectedDescription}
            onChange={this.handleChange("selectedDescription")}
            disabled={this.state.isLoading}
          />
        </FormControl>
        <Grid container margin="normal" spacing={2}>
          <Grid item md={5} xs={12}>
            <FormControl fullWidth margin="normal">
              <Button
                onClick={() => {
                  this.setState({ isOriginsDialogOpen: true });
                }}
                pr="0"
                disabled={this.state.isLoading}
                startIcon={
                  this.state.selectedOrigin
                    ? entityIcon(this.state.selectedOrigin.value).icon
                    : null
                }
              >
                {this.state.selectedOrigin
                  ? this.state.selectedOrigin.value.value
                  : "Origen"}
              </Button>
              <SelectorDialog
                isOpen={this.state.isOriginsDialogOpen}
                options={this.state.optionsOrigins}
                selectedItem={this.state.selectedOrigin}
                title="Origen"
                onClose={() => {
                  this.setState({ isOriginsDialogOpen: false });
                }}
                onSelect={(selectedOrigin) => {
                  this.setState({
                    selectedOrigin,
                    isOriginsDialogOpen: false,
                    selectedCurrency:
                      selectedOrigin.value.type === "account"
                        ? this.state.optionsCurrencies.find(
                            (currency) =>
                              currency.value === selectedOrigin.value.currency
                          ).value
                        : this.state.selectedCurrency,
                  });
                }}
              />
            </FormControl>
          </Grid>
          <Grid
            item
            md={2}
            xs={12}
            justifyContent="center"
            alignItems="center"
            style={{ display: "flex" }}
          >
            <Hidden xsDown>
              <ArrowForwardIcon />
            </Hidden>
            <Hidden mdUp>
              <ArrowDownIcon />
            </Hidden>
          </Grid>
          <Grid item md={5} xs={12}>
            <FormControl fullWidth margin="normal">
              <Button
                onClick={() => {
                  this.setState({ isDestinationsDialogOpen: true });
                }}
                disabled={this.state.isLoading}
                startIcon={
                  this.state.selectedDestination
                    ? entityIcon(this.state.selectedDestination.value).icon
                    : null
                }
              >
                {this.state.selectedDestination
                  ? this.state.selectedDestination.value.value
                  : "Destino"}
              </Button>
              <SelectorDialog
                isOpen={this.state.isDestinationsDialogOpen}
                options={this.state.optionsDestinations}
                selectedItem={this.state.selectedDestination}
                title="Destino"
                onClose={() => {
                  this.setState({
                    isDestinationsDialogOpen: false,
                  });
                }}
                onSelect={(selectedDestination) => {
                  this.setState({
                    selectedDestination,
                    isDestinationsDialogOpen: false,
                    selectedCurrency:
                      selectedDestination.value.type === "account"
                        ? this.state.optionsCurrencies.find(
                            (currency) =>
                              currency.value ===
                              selectedDestination.value.currency
                          ).value
                        : this.state.selectedCurrency,
                  });
                }}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container margin="normal" spacing={2}>
          <Grid item xs={4}>
            <FormControl fullWidth margin="normal">
              <SelectDownshift
                options={this.state.optionsCurrencies}
                selectedItem={this.state.selectedCurrency}
                onChange={(selectedCurrency) => {
                  this.setState({ selectedCurrency });
                }}
                id="moneda"
                label="Moneda"
                disabled={this.state.isLoading}
              />
            </FormControl>
          </Grid>
          <Grid item xs={8}>
            <FormControl fullWidth margin="normal">
              <TextField
                required
                id="monto"
                label="Monto"
                value={this.state.selectedAmount}
                onChange={this.handleChange("selectedAmount")}
                type="number"
                disabled={this.state.isLoading}
              />
            </FormControl>
          </Grid>
        </Grid>
        <FormControl fullWidth>
          <Button
            color="primary"
            disabled={!this.validate()}
            onClick={this.submitExpense.bind(this)}
          >
            Guardar
          </Button>
        </FormControl>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={this.state.snackOpen}
          onClose={() => {
            this.setState({ ...INITIAL_STATE, isLoading: false });
          }}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">{this.state.snackContent}</span>}
        />
      </div>
    );
  }
}

export default ExpenseForm;
