import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

import sortBy from "lodash.sortby";
import keyBy from "lodash.keyby";

import { entityIcon } from "./helpers";

import MUIDataTable from "mui-datatables";

const styles = theme => ({
  row: {
    height: 50
  },
  firstCol: {
    width: 50
  },
  root: {
    minWidth: 275
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  }
});

class LatestTransactions extends Component {
  state = {
    data: [],
    loading: true
  };

  async componentDidMount() {
    const { client } = this.props;

    const [origins, destinations, latestTransactions] = await Promise.all([
      client.getOrigins(),
      client.getDestinations(),
      client.getLatestTransactions()
    ]);

    // sort transactions by date
    const transactions = sortBy(latestTransactions.result.values, t => {
      const [day, month, year] = t[0].split("/");
      return new Date(parseInt(year), parseInt(month) - 1, day);
    });
    transactions.reverse();

    this.setState({
      data: transactions,
      loading: false,
      originsByName: keyBy(origins, "value"),
      destinationsByName: keyBy(destinations, "value")
    });
  }

  render() {
    const { loading } = this.state;
    if (loading) {
      return (
        <div
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            zIndex: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <CircularProgress size={80} />
        </div>
      );
    }

    return (
      <MUIDataTable
        data={this.state.data.map(d => {
          const o = entityIcon(this.state.originsByName[d[2]]);
          const ds = entityIcon(this.state.destinationsByName[d[3]]);

          return [
            d[0],
            d[1],
            <span>
              {o ? o.icon : null}
              {d[2]}
            </span>,
            <span>
              {ds ? ds.icon : null}
              {d[3]}
            </span>,
            `${d[6]} ${d[4]}`,
            Math.round(d[7] * 100) / 100
          ];
        })}
        columns={[
          "Fecha",
          "Descripción",
          "Origen",
          "Destino",
          "Importe",
          "Importe USD"
        ]}
        options={{
          responsive: "scrollMaxHeight",
          selectableRows: false,
          rowsPerPage: 50,
          download: false,
          print: false,
          viewColumns: false
        }}
      />
    );
  }
}

export default withStyles(styles)(LatestTransactions);
