import React from "react";

import ExpenseForm from "./ExpenseForm.js";
import Button from "@material-ui/core/Button";

export default ({ GoogleAuth, client }) => {
  return GoogleAuth && GoogleAuth.isSignedIn.get() ? (
    <ExpenseForm client={client} />
  ) : (
    <Button onClick={() => GoogleAuth.signIn()}>Sign In</Button>
  );
};
