class SheetClient {
  constructor(gapi, spreadsheetId) {
    this.gapi = gapi;
    this.spreadsheetId = spreadsheetId;
  }

  getMonthlyExpenses() {
    return this._getRange("Resumen Mensual Gastos", {
      valueRenderOption: "UNFORMATTED_VALUE",
      dateTimeRenderOption: "FORMATTED_STRING"
    });
  }

  async getOrigins() {
    const [accountsWithCurrency, incomeSources] = await Promise.all([
      this._getRange("CuentasConMoneda"),
      this._getRange("Ingresos")
    ]);

    return [
      ...accountsWithCurrency.result.values.map(([accountName, currency]) => ({
        value: accountName,
        type: "account",
        currency
      })),
      ...incomeSources.result.values.map(([incomeSourceName]) => ({
        value: incomeSourceName,
        type: "incomeSource"
      }))
    ];
  }

  async getDestinations() {
    const [accountsWithCurrency, budgetCategories] = await Promise.all([
      this._getRange("CuentasConMoneda"),
      this._getRange("CATEGORIASPRESUPUESTO")
    ]);

    return [
      ...budgetCategories.result.values.map(([budgetCategoryName]) => ({
        value: budgetCategoryName,
        type: "budgetCategory"
      })),
      ...accountsWithCurrency.result.values.map(([accountName, currency]) => ({
        value: accountName,
        type: "account",
        currency
      }))
    ];
  }

  getCurrencies() {
    return this._getRange("Monedas");
  }

  getAccountStatus() {
    return this._getRange("Estado de Cuentas!A:D");
  }

  getExchangeRateToUSD(currency) {
    switch (currency) {
      case "USD":
        return Promise.resolve(1);
      case "EUR":
      case "ARS":
        return this._getRange(currency).then(
          response => response.result.values[0][0]
        );
      default:
        return Promise.resolve(0);
    }
  }

  async getLatestTransactions() {
    const firstResp = await this._getRange("LastRow");
    const lastRow = parseInt(firstResp.result.values[0][0]);
    return this._getRange(`Movimientos!A${lastRow > 100 ? lastRow - 100 : 2}:H`, {
      valueRenderOption: "UNFORMATTED_VALUE",
      dateTimeRenderOption: "FORMATTED_STRING"
    });
  }

  appendTransaction(
    date,
    description,
    origin,
    destination,
    currency,
    rate,
    amount
  ) {
    return this.gapi.client.sheets.spreadsheets.values.append(
      {
        spreadsheetId: this.spreadsheetId,
        range: "Movimientos!A:A",
        insertDataOption: "INSERT_ROWS",
        valueInputOption: "USER_ENTERED"
      },
      {
        majorDimension: "ROWS",
        values: [
          [
            date,
            description,
            origin,
            destination,
            currency,
            rate,
            amount,
            "=F:F*G:G",
            `=IF(E:E="ARS";(G:G)/VLOOKUP(A:A;'Dolar Blue'!A:C;2;TRUE);H:H)`,
          ]
        ]
      }
    );
  }

  _getRange(range, options = {}) {
    return this.gapi.client.sheets.spreadsheets.values.get({
      spreadsheetId: this.spreadsheetId,
      range: range,
      ...options
    });
  }
}

export default SheetClient;
