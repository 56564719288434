import React from "react";
import AttachMoney from "@material-ui/icons/AttachMoney";
import PlayForWork from "@material-ui/icons/PlayForWork";
import Category from "@material-ui/icons/Category";

export function entityIcon(entity, fontSize = "small") {
  if (!entity) return null;
  let icon, secondaryText;
  switch (entity.type) {
    case "account":
      icon = <AttachMoney fontSize={fontSize} />;
      secondaryText = entity.currency;
      break;
    case "incomeSource":
      icon = <PlayForWork fontSize={fontSize} />;
      break;
    case "budgetCategory":
      icon = <Category fontSize={fontSize} />;
      break;
    default:
      throw Error(`Unknown entity type: ${entity.type}`);
  }

  return { icon, secondaryText };
}
