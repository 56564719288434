import React, { Component } from "react";

import CircularProgress from "@material-ui/core/CircularProgress";

import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { createTheme } from "@material-ui/core/styles";

const defaultTheme = createTheme();

class AccountStatus extends Component {
  state = {
    data: [],
    loading: true
  };

  async componentDidMount() {
    const { client } = this.props;

    const resp = await client.getAccountStatus();

    this.setState({
      data: resp.result.values.slice(1),
      loading: false
    });
  }

  render() {
    const { data } = this.state;

    if (this.state.loading) {
      return (
        <div
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            zIndex: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <CircularProgress size={80} />
        </div>
      );
    }

    return (
      <TableContainer
        component={Paper}
        style={{ margin: defaultTheme.spacing(2) }}
        elevation="3"
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell fontWeight="fontWeightBold">Cuenta</TableCell>
              <TableCell>Saldo</TableCell>
              <TableCell>Saldo USD</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((n, i) => {
              return (
                <TableRow key={i}>
                  <TableCell>{`${n[0]} (${n[2]})`}</TableCell>
                  <TableCell>{n[1]}</TableCell>
                  <TableCell>{n[3]}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

export default AccountStatus;
