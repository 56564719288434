import React, { Component } from "react";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

export default class SelectDownshift extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredItems: props.options,
    };
  }

  handleStateChange = (changes, stateAndHelpers) => {
    if (typeof changes.inputValue === 'string') {
      const filteredItems = this.props.options.filter(item =>
        item.label.toLowerCase().includes(changes.inputValue.toLowerCase())
      );
      this.setState({ filteredItems });
    }
  };

  render() {
    const { filteredItems } = this.state;

    return (
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={this.props.selectedItem}
          onChange={(e) => this.props.onChange(e.target.value)}
        >
          {(filteredItems.length == 0 ? this.props.options : filteredItems).map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
    );
  }
}
